.button{
    background-color: var(--theme-color);
    color: white;
    font-size: calc(var(--font-size)/6.2);
    height: 55px;
    line-height: 35px;
    border-radius: var(--border-radius);
    padding: 10px 20px;
    text-align: center;
    outline: none;
    border: none;
    cursor: pointer;
    font-family: "Poppins";
}
.button:hover:enabled{
    background-color: var(--theme-color-dark);
}
.button:focus{
    outline: var(--focus-outline);
}
.iconButton{
    background-color: var(--background-color-blank);
    outline: none;
    border: none;
    display: block;
    padding: 10px;
    cursor: pointer;
}
.iconButton:focus{
    outline: var(--focus-outline);
}
.iconButton:hover{
    background-color: var(--background-color-hover);
}
.iconButtonSelected{
    border-bottom: 3px solid var(--theme-color);
}
.appStoreButton{
    display: block;
    width: 200px;
    height: 70px;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
}
.appStoreButton:focus{
    outline: var(--focus-outline);
}
.appStoreButtonContent{
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}
.iconTextButton{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--border-radius);
    font-size: 17px;
    font-family: var(--font-family);
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    padding: 10px 8px;
    user-select: none;
}
.iconTextButton:focus{
    outline: var(--focus-outline);
}
.iconTextButton:hover:enabled{
    background-color: rgba(0,0,0,.07);
}
.iconTextButtonPrimary{
    padding: 10px 15px;
    background-color: var(--theme-color);
    color: white;
}
.iconTextButton:disabled{
    cursor: default;
}
.iconTextButtonPrimary:hover:enabled{
    background-color: var(--theme-color-dark);
}
.iconTextButtonStack{
    background-color: white;
    border-radius: var(--border-radius);
    position: relative;
    z-index: 10;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.134);
}
.iconTextButtonStack .iconTextButton{
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    padding: 15px 15px;
    width: 100%;
    justify-content: left;
}
.iconTextButtonStack .iconTextButton:last-child{
    border-bottom: none;
}
.forwardButton{
    display: flex;
    background-color: transparent;
    border: none;
    user-select: none;
    cursor: pointer;
    border-radius: var(--border-radius);
    padding: 10px 10px 10px 15px;
}
.forwardButton:hover:enabled{
    background-color: rgba(0,0,0,.07);
}
.forwardButton:focus{
    outline: var(--focus-outline);
}
.forwardButtonLeft{
    font-family: var(--font-family);
    font-size: 17px;
}
.forwardButtonRight{
    transition: .2s;
}
.forwardButton:hover:enabled .forwardButtonRight{
    transform: translateX(3px);
}