.landingSection{
    height: calc(100vh - var(--bg_height_i) * 2 - 230px);
}
.landingSectionFirst{
    display: flex;
    justify-content: center;
    height: 600px;
    overflow-y: visible;
}
.landingFirstLeft{
    padding-top: 10%;
    width: calc(100% - 530px);
}
.landingTitleLogo{
    width: 240px;
    height: 48px;
    /* background-image: url(/brand/logo-full-short.svg); */
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: left;
    margin-left: -8px;
    /* background-color: white; */
}
.landingTitle{
    font-size: calc(var(--font-size) / 2.5);
    font-family: var(--font-family);
    font-weight: 600;
    margin: 0;
    margin-bottom: 10px;
    line-height: 125%;
}
.landingDescription{
    font-size: calc(var(--font-size) / 5.5);
    margin: 0;
    font-family: var(--font-family);
    font-weight: 300;
}
.landingFirstRightPerson{
    width: 400px;
    margin-top: 120px;
    /* height: calc(500px); */
    /* background-image: url(/standing-crop.png); */
    background-size: 100%;
    background-repeat: no-repeat;
    margin-left: 70px;
}
.landingSectionSecond{
    background-color: rgb(var(--theme-color));
    position: relative;
    z-index: 2;
}
.landingSectionSecond *{
    color: black;
}
.landingSectionThird{
    background-color: var(--background-color);
    padding-bottom: 300px;
}
.landingSectionTitle{
    text-align: center;
    padding-top: 80px;
    font-size: calc(var(--font-size) / 4);
    font-family: var(--font-family);
    font-weight: 300;
    letter-spacing: .3em;
    padding-left: .3em;
    margin-bottom: 30px;
}
.landingSectionDescription{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: calc(var(--font-size) / 10.5);
    text-align: center;
    max-width: 700px;
    margin: 0 auto;
}
.landingSectionIntro, .landingSectionIntro span{
    font-size: calc(var(--font-size) / 6);
    text-align: center;
    width: 90vw;
    max-width: 720px;
    margin: 0 auto;
    font-family: 'Noto Serif TC';
    letter-spacing: .1em;
    position: relative;
    /* z-index: 2; */
}
.landingSectionIntro::before{
    content: "";
    display: block;
    position: relative;
    z-index: 1;
    top: 130px;
    left: -35px;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background-color: rgb(var(--theme_color), .2);
}
.landingSectionIntro::after{
    content: "";
    display: block;
    position: relative;
    z-index: 1;
    top: -50px;
    left: calc(100% - 80px);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: rgb(var(--theme_color), .8);
}
.landingSectionImage{
    width: 95%;
    max-width: 700px;
    height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin: 50px auto;
    border: 3px solid #444;
}
.landingSectionGrid{
    max-width: 800px;
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, 160px);
    gap: 10px;
    margin: 40px auto 0;
    justify-content: center;
}
.landingSectionGridItem{
    width: 140px;
    height: 140px;
    margin: 0 auto;
    /* background-color: #ffffff88; */
}
.landingRadiation{
    width: 100vw;
    height: 150vh;
    max-width: 2000px;
    max-height: 2000px;
    transform: translateX(-50%) translateY(-50%);
    z-index: -20;
    position: relative;
    background-color: #e5e5f7;
    background-image: radial-gradient(#0373fc 0.6000000000000001px, #e5e5f7 0.6000000000000001px);
    background-size: 10px 10px;
    /* background-image: radial-gradient(circle at center center, #0373fc, #e5e5f7), repeating-radial-gradient(circle at center center, #0373fc, #0373fc, 10px, transparent 20px, transparent 10px); */
    background-blend-mode: multiply;
    left: 200px;
    top: -100px;
    background-color: #e5e5f7;
    background-image:  linear-gradient(#0373fc 3.4000000000000004px, transparent 3.4000000000000004px), linear-gradient(90deg, #0373fc 3.4000000000000004px, transparent 3.4000000000000004px), linear-gradient(#0373fc 1.7000000000000002px, transparent 1.7000000000000002px), linear-gradient(90deg, #0373fc 1.7000000000000002px, #e5e5f7 1.7000000000000002px);
    background-size: 85px 85px, 85px 85px, 17px 17px, 17px 17px;
    opacity: .5;
    background-position: -3.4000000000000004px -3.4000000000000004px, -3.4000000000000004px -3.4000000000000004px, -1.7000000000000002px -1.7000000000000002px, -1.7000000000000002px -1.7000000000000002px;
}
.landingRadiationOverlay{
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center center, #ffffff11 0%, #ffffff 60%);
}
.landingRadiationOverlaySmaller{
    background: radial-gradient(circle at center center, #ffffff88 0%, #ffffff 40%);
}
@media(max-width: 1200px){
    .landingTitle{
        font-size: calc(var(--font-size) / 3.7);
    }
    .landingSectionSecond{
        background-color: rgb(var(--theme_color));
        position: relative;
        z-index: 2;
        padding-bottom: 100px;
    }
}
@media(max-width: 1060px){
}
@media(max-width: 970px){
    .landingSectionFirst{
        display: block;
    }
    .landingSectionFirst .landingFirstLeft{
        margin: 0 auto;
        width: 90%;
        padding-top: 20px;
    }
    .landingSectionFirst .landingFirstRight{
        margin: -100px auto 0;
    }
    .landingSectionFirst .landingFirstRightPerson{
        margin: 0 auto;
        margin-top: 170px;
    }
    .landingSectionSecond{
        height: 300px;
    }
    .landingSectionThird{
        height: 900px;
    }
    .landingSectionThird .landingFirstLeft{
        height: 300px;
    }
    .landingFirstRightSearch{
        width: unset;
        padding-top: 100px;
    }
}
@media(max-width: 900px){
    .landingFirstRight{
        margin-top: -80px;
    }
}
@media(max-width: 720px){
    .landingFirstRight{
        margin-top: -40px;
    }
    .landingTitleLogo{
        width: 200px;
        height: 40px;
    }
}
@media(max-width: 500px){
    .landingTitle{
        font-size: calc(var(--font-size) / 4.15);
    }
}
@media(max-width: 480px){
    .landingFirstRight{
        margin-top: 0;
    }
}