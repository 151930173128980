.connectionPopupTop{
    position: relative;
}
.connectionPopupOverlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
}
.connectionPopupOverlay::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255,255,255,.5); */
    z-index: 1;
}
.connectionPopupTopContent{
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 600px;
    padding: 150px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: var(--deck-card-width);
}
.connectionPopupTopContentName{
    font-size: calc(var(--font-size)/5);
    font-family: var(--font-family);
    margin: 5px 0;
    padding: 0;
    text-align: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.connectionPopupTopContentDescription{
    font-size: calc(var(--font-size)/6);
    padding: 0;
    color: #444;
    font-family: "Sanchez";
    text-align: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}
.connectionPopupButtons{
    position: absolute;
    top: 100%;
    width: calc(100%);
    display: flex;
    transform: translateY(-100%) translateX(-15px);
    z-index: 20;
}