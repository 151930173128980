.teamMemberCard{
    overflow: hidden;
}
.teamMemberCardTitle{
    font-family: var(--font-family);
    font-size: 20px;
    margin-bottom: 0px;
    margin-top: 10px;
}
.teamMemberCardRole{
    font-family: var(--font-family);
    font-size: 13px;
    margin-top: 0px;
    margin-bottom: 10px;
}
.teamMemberCardDescription{
    font-family: var(--font-family);
    font-size: 17px;
    margin-top: 10px;
    hyphens: auto;
}
.teamMemberCardPhoto{
    width: calc(100% + 58px);
    transform: translateX(-29px) translateY(-30px);
    height: 180px;
    margin: 10px auto -20px;
    border-radius: 7px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background-color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}