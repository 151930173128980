@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sanchez:ital@0;1&display=swap');
:root{
  --font-family: 'Poppins', sans-serif;
  --font-size: 120px;
  --border-radius: 10px;

  --top-bar-height: 50px;
  --deck-card-width: 400px;
  
  --theme-color: #1050ff;
  --theme-color-dark: #002cca;
  --background-color-blank: white;
  --background-color-theme: #eff2ff;
  --background-color-hover: rgba(0,0,0,0.1);

  --focus-outline: 2px solid black;
}
html, #root{
  overflow-x: hidden;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background-color: var(--background-color-blank);
}
*{
  box-sizing: border-box;
  transform: .15s ease-in-out;
  -webkit-appearance:none;
  -webkit-tap-highlight-color:rgba(0,0,0,.15);
  scroll-behavior: smooth;
}
*::selection{
  background-color: #9d9d9d;
  color: black;
}
hr{
  border: none;
  border-top: 1px solid #dadada;
  margin: 30px 0;
}
main{
  min-height: calc(100vh - var(--top-bar-height));
}
.emphasis{
  color: var(--theme-color);
}
.span-cursor{
  display: inline-block;
  width: 3px;
  height: 1em;
  background-color: var(--theme-color);
  animation: span-cursor 1s infinite;
  transform: translateY(.15em);
}
.glassmorphic{
  background: rgba(255, 255, 255, .20);
  border-radius: 15px;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  box-shadow: 0 0px 32px 0 rgba(31, 38, 135, 0.18);
  border: 1px solid rgba(255, 255, 255, 0.18);
}
@keyframes span-cursor {
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}
.contentViewer{
  font-family: var(--font-family);
}
@media(max-width: 600px){
  :root{
    --font-size: 100px;
  }
}