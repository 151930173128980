.notificationList{
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    background-color: var(--background-color-theme);
}
.notificationItem{
    padding: 15px;
    border-bottom: 1px solid #ccc;
}
.notificationItem:last-child{
    border-bottom: none;
}