.icon{
    font-size: 25px;
}
.iconPlaceholder{
    width: 100%;
    height: 200px;
    opacity: .3;
    position: relative;
}
.iconPlaceholderIcon{
    text-align: center;
    position: absolute;
    width: 100%;
    top: 30%;
}
.iconPlaceholderContent{
    position: absolute;
    top: 60%;
    font-family: var(--font-family);
    font-size: calc(var(--font-size)/5);
    text-align: center;
    width: 100%;
    font-weight: 500;
}