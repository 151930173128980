.input{
    padding: 10px 20px;
    height: 55px;
    line-height: 35px;
    display: block;
    border-radius: var(--border-radius);
    border: 1px solid #ccc;
    font-size: 17px;
    font-family: var(--font-family);
}
.formLabel{
    margin-bottom: 0px;
    font-size: calc(var(--font-size)/6.2);
    font-family: var(--font-family);
    font-weight: 300;
    text-align: left;
}