.deck{
    width: calc(var(--deck-card-width) + 20px);
    height: calc(var(--deck-card-width) * 0.95);
    border-radius: var(--border-radius);
    position: relative;
    justify-content: center;
    display: flex;
    cursor: default;
    transition: .08s;
    user-select: none;
    margin: 0 auto;
}
.deck:hover{
    background-color: rgba(0, 0, 0, 0.023);
    scale: 1.03;
}
.deckCover{
    width: 100%;
    height: calc(var(--deck-card-width) / 2.5);
    z-index: 9;
    position: relative;
    top: 170px;
    background-color: rgba(255, 255, 255, 0.82);
    padding: 20px;
}
.deckSelected .deckCoverTitle{
    text-decoration: underline;
}
.deckCoverTitle{
    font-family: "Sanchez";
    font-size: calc(var(--font-size) / 6);
    color: #444;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}
.deckCoverTitleIndicator{
    display: none;
    color: var(--theme-color);
}
.deckSelected .deckCoverTitleIndicator{
    display: inline;
    position: absolute;
    top: 55px;
    left: -3px;
}
.deckCoverDescription{
    font-family: "Sanchez";
    font-size: calc(var(--font-size) / 7.5);
    color: #444;
    margin: 0;
    padding: 0;
}
.deck:hover .deckCoverTitle{
    text-decoration: underline;
}