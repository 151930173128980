.quotation{
    font-size: 37px;
    font-family: "Sanchez";
    background: rgba(255, 255, 255, .20);
    border-radius: 15px;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    box-shadow: 0 0px 32px 0 rgba(31, 38, 135, 0.18);
    border: 1px solid rgba(255, 255, 255, 0.18);
    padding: 40px 35px;
    margin: 0 20px;
    text-align: center;
    overflow: visible;
}
.quotation::before{
    content: "“";
    font-size: 150px;
    position: absolute;
    top: -80px;
    left: 15px;
    font-family: var(--font-family);
    color: var(--theme-color);
    display: block;
}
.quotationSource{
    font-size: 23px;
    font-family: "Sanchez";
    margin-top: 20px;
    color: #444;
    font-style: italic;
    text-align: right;
    margin-right: 20px;
}
.quotationSource::before{
    content: "— ";
}
@media(max-width: 1150px){
    .quotation{
        font-size: 30px;
    }
    .quotationSource{
        font-size: 20px;
    }
}
@media(max-width: 750px){
    .quotation{
        padding: 30px 15px;
        margin: 0 10px;
        font-size: 26px;
    }
}