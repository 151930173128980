.phone{
    width: 330px;
    height: 600px;
    /* background-color: #333; */
    /* border: 1px solid #666; */
    box-shadow: 0 0 20px 0 rgba(0,0,0,.2);
    border-radius: 30px;
    padding: 15px;
    transform: rotateX(-10deg) rotateY(-10deg) rotateZ(-10deg);
    background: rgb(0,164,255);
    background: linear-gradient(157deg, rgba(0,164,255,1) 0%, rgba(221,139,255,1) 35%, rgba(0,155,255,1) 100%);
}
.phoneScreen{
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 19px;
    overflow: visible;
    /* border: 1px solid #666; */
    background-color: #e5e5f7;
    /* opacity: 0.8; */
    background-image:  repeating-radial-gradient( circle at 50% 50%, transparent 0, #e5e5f7 110px ), repeating-linear-gradient( #b6d1ff55, #b6d1ff );
}
.phoneScreenOverlay{
    width: 100%;
    height: 100%;
    background-image: url(../../../public/img/branding/logo-self.svg);
    background-repeat: no-repeat;
    background-position: center;
    overflow: visible;
    background-size: 45%;
}
.phoneScreenContent{
    width: 100%;
    height: 100%;
    border-radius: 19px;
    /* background-color: pink; */
    width: 300px;
    height: 570px;
    border: 1px solid #ccc;
    backdrop-filter: none;
    background-image: url(../../../public/img/content/demo-card-details.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.phoneScreenContentTop{
    margin-bottom: 15px;
    width: 100%;
    height: 290px;
    border: 1px solid #ccc;
    border-radius: 19px;
    background-image: url(../../../public/img/content/share-qrcode.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
.phoneScreenContentBottom{
    width: 100%;
    height: 147px;
    border: 1px solid #ccc;
    border-radius: 19px;
    background-image: url(../../../public/img/content/share-link.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}
@media(min-width: 1300px){
    .root{
        transform: scale(1.15);
    }
}
@media(max-width: 800px){
    .root{
        transform: scale(0.8);
    }
}
@media(max-width: 600px){
    .root{
        transform: scale(0.6);
    }
}
@media(max-width: 500px){
    .root{
        transform: scale(0.5) translateX(-100px);
    }
}