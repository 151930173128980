.deckCard{
    width: 100%;
    max-width: var(--deck-card-width);
    aspect-ratio: 1.6 / 1;
    border-radius: var(--border-radius);
    border: 1px solid #e6e6e6;
    background-color: #e2e2e2; /* Base color */
    border-radius: 10px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: visible;
    transition: .08s;
    margin: 0 auto;
}
.deckCardClickable{
    cursor: pointer;
}
.deckCardClickable:hover{
    transform: scale(1.03);
}
.deckCardFunctional:hover{
    cursor: default;
    scale: 1.03;
}
.deckCardExample1::before {
    content: '';
    position: absolute;
    top: -0%;
    left: -0%;
    width: 100%;
    border-radius: 8px;
    height: 100%;
    background: linear-gradient(45deg, rgb(0, 255, 242) 0%, transparent 50%, rgb(216, 108, 255) 100%);
    opacity: 0.4;
    pointer-events: none;
}
.deckCardExample1::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.522);
}
.deckCardExample2::before {
    content: '';
    position: absolute;
    top: -0%;
    left: -0%;
    width: 100%;
    border-radius: 8px;
    height: 100%;
    background: linear-gradient(90deg, rgb(255, 190, 60) 0%, rgb(255, 68, 0) 50%, rgb(255, 0, 0) 100%);
    opacity: .6;
    pointer-events: none;
}
.deckCardExample2::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.522);
}
.deckCardNew{
    cursor: pointer;
}
.deckCardNew::before{
    content: '';
    position: absolute;
    top: -0%;
    left: -0%;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 15;
    background: linear-gradient(90deg, rgb(255, 191, 64) 0%, rgb(255, 219, 161) 50%, rgb(255, 218, 132) 100%);
    opacity: .6;
    border: 10px dashed #fff;
}
.deckCardNew::after {
    content: '';
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    box-shadow: inset 0 0 20px rgba(255, 255, 255, 0.522);
}
.deckCardExample{
    padding: 15px;
}
.deckCardExampleName{
    font-family: "Sanchez";
    padding-left: 20px;
    font-size: calc(var(--font-size) / 6);
    color: #444;
    margin: 30px 0 0;
    /* z-index: 10; */
    position: relative;
}
.deckCardExampleEmail{
    font-family: "Sanchez";
    padding-left: 20px;
    font-size: calc(var(--font-size) / 8);
    color: #444;
    margin: 5px 0 0;
    /* z-index: 10; */
    position: relative;
}
.deckCardContent{
    position: absolute;
    bottom: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(var(--deck-card-width) / 3.45);
    background-color: rgba(255, 255, 255, 0.82);
    padding: 20px;
}
.deckCardContentTitle{
    font-family: "Sanchez";
    font-size: calc(var(--font-size) / 6);
    color: #444;
    margin: 0;
    padding: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    position: relative;
}
.deckCardContentTitleIndicator{
    display: none;
    color: var(--theme-color);
}
.deckCardFunctional:hover .deckCardContentTitle{
    text-decoration: underline;
}
.deckCardSelected .deckCardContentTitleIndicator{
    display: inline;
    position: absolute;
    top: 35px;
    left: -3px;
}