.loginBox {
  margin: 170px auto;
  max-width: min(450px, 90vw);
  padding: 50px 40px 50px;
  border-radius: var(--border-radius);
}
.loginTitle {
  font-size: 25px;
  margin-bottom: 30px;
  color: black;
  font-weight: 600;
  font-family: "Poppins";
}
.loginHintText{
  font-family: var(--font-family);
  font-size: 16px;
  margin-top: 15px;
}