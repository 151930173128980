.logoBand{
    width: 100%;
    padding: 50px 20px 0;
    background-color: #ffffffa7;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    margin: 25px 0;
}
.logoBandGrid{
    display: grid;
    grid-template-columns: repeat(auto-fit, 200px);
    margin: 0 auto;
    justify-content: center;
}
.logoBandItem{
    width: 180px;
    margin: 0 10px;
    height: 150px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    transition: .15s ease-in-out;
    text-align: center;
    object-fit: contain;
}
.logoBandItem:hover{
    filter: none;
}
.logoBandFootnote{
    font-family: var(--font-family);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
    padding-bottom: 30px;
}
@media(max-width: 660px){
    .logoBandGrid{
        display: grid;
        grid-template-columns: repeat(auto-fit, 160px);
        margin: 0 auto;
        justify-content: center;
    }
    .logoBandItem{
        width: 140px;
        margin: 0 10px;
        height: 150px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        transition: .15s ease-in-out;
        text-align: center;
        object-fit: contain;
    }
}