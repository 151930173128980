.searchDemoMain{
    /* background-color: white; */
    border-radius: 30px;
    padding-bottom: 20px;
    box-shadow: 0 -15px 20px 0 rgba(43, 163, 255, 0.143);
    width: fit-content;
    margin: 0 auto;
    user-select: none;
    max-width: 100vw;
}
.searchDemoBoxOuter{
    background: rgb(0,164,255);
    background: linear-gradient(157deg, rgba(0,164,255,1) 0%, rgba(221,139,255,1) 35%, rgba(0,155,255,1) 100%);
    box-shadow: 0 0 30px 0 rgba(43, 163, 255, 0.206);
    border-radius: 30px;
    padding: 4px;
    display: flex;
    width: fit-content;
}
.searchDemoBox{
    width: 380px;
    background-color: white;
    border-radius: 26px;
    padding: 15px 20px;
    font-size: 19px;
    font-family: var(--font-family);
}
.searchDemoBoxRight{
    width: 60px;
    height: 60px;
}
.searchDemoResults{
    height: 600px;
    justify-content: center;
}
.searchDemoResultsOverlay{
    background: linear-gradient(to top, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
    width: 100%;
    height: 210px;
    transform: translateY(-200px);
    border-radius: 20px;
}
@media(max-width: 500px){
    .searchDemoMain{
        width: 100vw;
        position: absolute;
        left: 0;
        overflow-x: hidden;
        overflow-y: hidden;
    }
    .searchDemoBox{
        width: calc(100vw - 70px);
        background-color: white;
        border-radius: 26px;
        padding: 15px 20px;
        font-size: 19px;
        font-family: var(--font-family);
    }
}