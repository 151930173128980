.floatingChipsContainer{
    max-width: 100vw;
    width: 100%;
    position: relative;
}
.floatingChip{
    display: flex;
    background-color: white;
    box-shadow: 0 0 35px 5px rgba(0, 0, 0, 0.1);
    width: fit-content;
    border-radius: 50px;
    animation: moveAround 5s infinite;
}
.floatingChipLeft{
    width: 30px;
    height: 30px;
    transform: scale(.7);
    padding: 3px 13px;
}
.floatingChipRight{
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    padding: 0 15px 0 7px;
    font-family: "Sanchez";
}

@media(max-width: 900px){
    .floatingChipsContainer{
        max-width: 100vw;
        width: 100%;
        left: 0;
        overflow-x: hidden;
    }
}