.showcaseScreenBase{
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    overflow: visible;
}
.leftScreenOuter{
    border-radius: 30px;
    padding: 4px;
    overflow: hidden;
    width: fit-content;
    background: rgb(0,164,255);
    background: linear-gradient(47deg, rgba(0,164,255,1) 0%, rgba(221,139,255,1) 35%, rgba(0,155,255,1) 100%);
    box-shadow: 0 0 30px 0 rgba(43, 163, 255, 0.206);
    margin: 0 auto;
}
.leftScreen{
    width: calc(95vw - 8px);
    max-width: 1000px;
    aspect-ratio: 16/8.55;
    background-color: white;
    border-radius: 26px;
    box-shadow: 0 0 15px 0 rgb(255,255,255,.8);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
}