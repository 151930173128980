.avatar{
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: var(--border-radius);
    border: 3px solid #444;
    overflow: hidden;
    background-color: white;
}
.editable{
    opacity: 0;
    width: 100%;
    height: 40px;
    background-color: rgba(0,0,0,.5);
    color: white;
    text-align: center;
    line-height: 40px;
    top: calc(100% - 40px);
    position: relative;
    font-weight: 400;
    font-family: var(--font-family);
    transition: .15s;
    border: none;
    cursor: pointer;
    font-size: 17px;
}
.avatar:hover .editable{
    opacity: 1;
}
.avatarPlaceholder{
    width: 100%;
    height: 100%;
    opacity: .3;
    text-align: center;
    margin-top: 27%;
}