.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
    transition: .7s;
}
.backdropSearchMode{
    background-color: white;
    top: calc(var(--top-bar-height));
    overflow-y: auto;
    overflow-x: hidden;
}
.searchBar{
    width: 95%;
    max-width: 700px;
    background-color: white;
    padding: 10px;
    background-color: var(--theme-color);
    border-radius: 25px;
    background: linear-gradient(157deg, rgba(0,164,255,1) 0%, rgba(221,139,255,1) 35%, rgba(0,155,255,1) 100%);
    display: flex;
    transition: 1s;
}
.searchBarMoved{
    
}
.searchBarButton{
    background-color: transparent;
    border: none;
    display: block;
    width: 80px;
    height: 100%;
    cursor: pointer;
}
.searchBarButton:hover{
    transform: scale(1.1);
}