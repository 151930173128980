.jobPostItem{
    box-shadow: 0 0 10px 0 rgba(0, 65, 185, 0.134);
    padding: 25px;
    border-radius: var(--border-radius);
    margin-top: 25px;
}
.jobPostItemTitle{
    font-size: calc(var(--font-size)/5);
    font-weight: 600;
    margin-bottom: 10px;
    margin: 0;
    font-family: var(--font-family);
}
.jobPostItemDescription{
    font-size: calc(var(--font-size)/6.5);
    margin: 0;
    margin-top: 6px;
    color: #444;
    font-family: "Sanchez";
}