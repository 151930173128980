.progressBar{
    width: calc(100%);
    height: 25px;
    background: rgb(0,164,255);
    background: linear-gradient(303deg, rgba(0,164,255,1) 0%, rgba(221,139,255,1) 35%, rgba(0,155,255,1) 100%);
    border-radius: 500px;
    display: flex;
    justify-content: right;
    border: 1px solid #ccc;
    overflow: hidden;
}
.progressBarOverlay{
    height: 100%;
    background-color: white;
    transition: .2s;
}
.progressPercentage{
    font-family: var(--font-family);
    font-size: 16px;
    padding-left: 5px;
}