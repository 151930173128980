.myDeck{
    /* border: 3px solid #cecece; */
    width: 100%;
    height: calc(100vh - 200px);
}
.myDeckToolbar{
    width: 100%;
    height: 50px;
    /* background-color: #f9f9f9; */
    display: flex;
    /* box-shadow: 0 0 10px 0 rgba(0, 65, 185, 0.134); */
    position: fixed;
    z-index: 10;
    top: var(--top-bar-height);
    left: 0;
    border-bottom: 1px solid #ccc;
    background-color: white;
}
.myDeckContent{
    --deck-card-width: 300px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(calc(var(--deck-card-width) + 40px), 1fr));
    gap: 20px;
    margin-top: 25px;
    justify-content: center;
    overflow-y: visible;
    width: 100vw;
    padding-bottom: 100px;
    padding: 0 calc(var(--deck-card-width) / 4);
    grid-auto-rows: 1fr;
    position: relative;
}
.myDeckPathButton{
    cursor: pointer;
    user-select: none;
    border-radius: var(--border-radius);
}
.myDeckPathButton:hover{
    background-color: rgba(0,0,0,.07);
}
@media(max-width: 850px){
    .myDeckContent{
        --deck-card-width: calc(50vw - 140px);
    }
}
@media(max-width: 740px){
    .myDeckContent{
        --deck-card-width: calc(50vw - 80px);
        padding: 0 20px;
    }
}
@media(max-width: 500px){
    .myDeckContent{
        --deck-card-width: calc(70vw);
        padding: 0 8px;
        gap: 0;
    }
}