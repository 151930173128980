.topbar {
  background-color: white;
  width: 100vw;
  height: var(--top-bar-height);
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #ccc;
  position: fixed;
  top: 0;
  z-index: 100;
}
.left{
  padding-left: calc(10px);
  width: 150px;
  height: 100%;
}
.middle {
  width: calc(100vw - 150px - 200px);
  height: 100%;
}
.right{
  width: 200px;
  height: 100%;
  display: flex;
  justify-content: right;
  padding-right: 20px;
}
.searchBar{
  /* background-color: black; */
  background-color: #ffffff;
  color: black !important;
  width: 100%;
  height: 3.1vh;
  border-radius: 10px;
  margin-top: 5px; margin-bottom: 5px;
  outline: none;
  
}
.eapplico{
  font-family: var(--font-family);
  user-select: none;
  font-weight: 600;
  font-size: 25px;
  border: none;
  height: 48px;
  line-height: 50px;
  background-color: var(--background-color-blank);
  width: 100%;
  background-size: 90%;
  background-repeat: no-repeat;
  cursor: pointer;
  background-position: center;
}
