.notificationRoot{
    width: 300px;
    position: fixed;
    top: calc(100vh - 15px - 50px);
    top: calc(100dvh - 15px - 25px);
    left: 15px;
    transform: translateY(-100%);
    z-index: 300;
}
.notificationBlock{
    background-color: #222;
    color: white;
    padding: 15px;
    font-size: 17px;
    font-family: var(--font-family);
    margin-bottom: 15px;
    animation-name: notification-slide-in;
    animation-timing-function: ease-in-out;
    animation-duration: .15s;
    border: 1px solid white;
    border-radius: var(--border-radius);
}

@keyframes notification-slide-in {
    0%{
        transform: translateY(calc(100% + 40px));
    }
    100%{
        transform: translateY(0);
    }
}