.analysisGraphics{
    overflow: hidden;
    height: 100%;
    animation: breathing 1.5s infinite;
}
@keyframes breathing {
    0% {
        opacity: 0.8;
    }
    50% {
        opacity: 0.3;
    }
    100% {
        opacity: 0.8;
    }
}
.webcamWrapper{
    border-radius: var(--border-radius);
    width: fit-content;
    background-color: black;
    position: relative;
}
.webcamWrapperHint{
    font-family: var(--font-family);
    color: white;
    text-align: center;
    font-size: 15px;
    width: 260px;
    padding: 20px;
    position: absolute;
}
.checkCardDisplay{
    width: 100%;
    aspect-ratio: 16 / 9;
    background-color: black;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: var(--border-radius);
}
.loadingOverlay{
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(4px);
}
.editOverlay{
    width: 100%;
    height: 100%;
    position: relative;
}
.loadingOverlayText{
    font-family: var(--font-family);
    font-size: 20px;
    /* color: white; */
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}