.popupBaseLayer{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    overscroll-behavior: contain;
}
.popupContainer{
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 600px;
    height: 800px;
    max-height: 100vh;
    background-color: white;
    border-radius: var(--border-radius);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.241);
    overflow: hidden;
}
.popupContainer-small{
    max-width: 500px;
    height: 500px;
}
.popupContainer-mini{
    max-width: 500px;
    height: 280px;
}
.popupContainer-medium{
    max-width: 800px;
    height: 750px;
}
.popupContainer-slim{
    max-width: 600px;
    height: calc(100vh - 30px);
    max-height: 1000px;
}
.popupContainer-fullscreen{
    max-width: unset;
    height: calc(100vh);
    width: 100vw;
}
.popupContainerTop{
    display: flex;
    height: 50px;
    border-bottom: 1px solid #ccc;
}
.popupContainerTitle{
    width: calc(100% - 50px);
    display: block;
    height: 50px;
    margin: 0;
    padding: 0 0 0 20px;
    line-height: 50px;
    font-family: var(--font-family);
    font-size: 20px;
}
.popupContainerClose{
    width: 50px;
    height: 50px;
    cursor: pointer;
    display: block;
    padding: 0;
    border: none;
    background-color: transparent;
}
.popupContainerClose:hover{
    background-color: rgb(0,0,0,.07);
}
.popupContainerContent{
    padding: 15px 15px 0;
    overflow-y: auto;
    width: 100%;
    height: calc(100% - 50px - 1px);
}