.card{
    background-color: var(--background-color-theme);
    padding: 18px 28px;
    border-radius: var(--border-radius);
}
.noPadding{
    padding: 0;
}
.glassmorphicCard{
    background-color: rgba(255, 255, 255, 0.5);
}