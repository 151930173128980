.footer {
    /* background-color: var(--theme-color); */
    border-top: 10px solid var(--theme-color);
    font-family: var(--font-family);
    overflow: hidden;
}
.footerCenter{
    display: grid;
    grid-template-columns: repeat(auto-fill, 220px);
    justify-content: space-between;
    padding: 0 20px;
    justify-content: center;
    font-size: calc(var(--font-size)/7);
}
.footerColumn{
    align-items: start;
}
.footerColumnTitle{
    font-size: calc(var(--font-size)/6);
}
.footerColumnLink{
    list-style-type: none;
    padding-left: 0;
    font-size: calc(var(--font-size)/7);
    display: block;
    margin: 0 0 5px 0;
}
.footerColumnLink * {
    color: black;
    text-decoration: none;
}
.footerContent{
    text-align: center;
    font-size: calc(var(--font-size)/7);
    font-family: var(--font-family);
    color: #333;
}
.footerBottom{
    display: flex;
    border-top: 1px solid #ccc;
    height: 40px;
}
.footerBottomLeft{
    flex: 1;
    font-size: calc(var(--font-size)/7);
    font-family: var(--font-family);
    color: #333;
    text-align: left;
    line-height: 40px;
    padding: 0 20px;
}
.footerBottomRight{
    flex: 1;
    font-size: calc(var(--font-size)/7);
    font-family: var(--font-family);
    color: #333;
    text-align: right;
    line-height: 40px;
    padding: 0 20px;
}