.radiation{
    width: 150vw;
    height: 150vh;
    max-width: 2000px;
    max-height: 2000px;
    transform: translateX(-50%) translateY(-50%);
    z-index: -20;
    position: relative;
    background-color: #e5e5f7;
    background-image: radial-gradient(#0373fc 0.6000000000000001px, #e5e5f7 0.6000000000000001px);
    background-size: 10px 10px;
    /* background-image: radial-gradient(circle at center center, #0373fc, #e5e5f7), repeating-radial-gradient(circle at center center, #0373fc, #0373fc, 10px, transparent 20px, transparent 10px); */
    background-blend-mode: multiply;
    left: 200px;
    top: -100px;
    background-color: #e5e5f7;
    background-image:  linear-gradient(#0373fc 3.4000000000000004px, transparent 3.4000000000000004px), linear-gradient(90deg, #0373fc 3.4000000000000004px, transparent 3.4000000000000004px), linear-gradient(#0373fc 1.7000000000000002px, transparent 1.7000000000000002px), linear-gradient(90deg, #0373fc 1.7000000000000002px, #e5e5f7 1.7000000000000002px);
    background-size: 85px 85px, 85px 85px, 17px 17px, 17px 17px;
    opacity: .5;
    background-position: -3.4000000000000004px -3.4000000000000004px, -3.4000000000000004px -3.4000000000000004px, -1.7000000000000002px -1.7000000000000002px, -1.7000000000000002px -1.7000000000000002px;
}
.radiationOverlay{
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at center center, #ffffff11 0%, #ffffff 60%);
}