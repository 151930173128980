.deckScheme{
    width: 100%;
    height: 100%;
}
.deckSchemeTop{
    border-bottom: 1px solid #ccc;
    height: 50px;
    width: 100%;
    display: flex;
    overflow: hidden;
}
.deckSchemeTopLeft{
    width: calc(100% - 100px);
    height: 100%;
    line-height: 50px;
    font-weight: 500;
    font-size: 19px;
    padding-left: 20px;
    font-family: var(--font-family);
}
.deckSchemeTopRight{
    width: calc(100px);
    height: 100%;
    overflow: hidden;
}
.deckSchemeContent{
    width: 100%;
    height: calc(100% - 50px);
    overflow-y: auto;
    padding: 0 15px;
}
.deckSchemeItem{
    display: flex;
    /* border-bottom: 1px solid #ccc; */
    user-select: none;
    font-family: var(--font-family);
    padding: 0 15px;
    border-radius: var(--border-radius);
}
.deckSchemeItem:last-child{
    border-bottom: none;
}
.deckSchemeItemSelected{
    background-color: rgba(0,0,0,.07);
}
.deckSchemeItemIcon{
    width: 35px;
    height: 50px;
}
.deckSchemeItemName{
    width: calc(100% - 35px);
    height: 50px;
    line-height: 50px;
    font-size: 19px;
}
