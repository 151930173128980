.connectionHorizontalItem{
    width: 130px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    padding-bottom: 15px;
    cursor: pointer;
}
.connectionHorizontalItem:hover{
    background-color: rgba(0,0,0,.07);
}
.connectionListHorizontalWrap{
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 20px 0;
}
.connectionHorizontalItemName{
    font-size: calc(var(--font-size)/7);
    /* font-weight: 600; */
    font-family: var(--font-family);
    margin: 10px 0 0;
    padding: 0 10px;
    text-align: center;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.connectionHorizontalItemDescription{
    font-size: calc(var(--font-size)/7.5);
    padding: 0 10px;
    color: #444;
    font-family: "Sanchez";
    text-align: left;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.connectionVerticalItem{
    width: 100%;
    max-width: 350px;
    border: 1px solid #ccc;
    border-radius: var(--border-radius);
    padding: 10px;
    display: flex;
    cursor: pointer;
}
.connectionVerticalItem:hover{
    background-color: rgba(0,0,0,.07);
}
.connectionVerticalItemRight{
    width: calc(100% - 100px);
    padding-left: 15px;
}
.connectionVerticalItemName{
    font-size: calc(var(--font-size)/6);
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    text-align: left;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.connectionVerticalItemDescription{
    font-size: calc(var(--font-size)/7.5);
    padding: 0 0;
    color: #444;
    font-family: "Sanchez";
    text-align: left;
    overflow-x: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.connectionListVertical{
    display: grid;
    grid-template-columns: repeat(auto-fill, 350px);
    gap: 20px;
}