.centerViewer{
    margin: 50px auto;
    width: 95vw;
    max-width: 800px;
    padding-top: 30px;
}
.tabsTop{
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 10px;
    border-bottom: 1px solid #ddd;
    background-color: var(--background-color);
    white-space: nowrap;
    border-radius: 0;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    text-align: left;
    background-color: var(--background-color-blank);
}
.tabsTop::-webkit-scrollbar {
    display: none;
}
.tabsTopSticky{
    position: sticky;
    top: var(--top-bar-height);
    z-index: 1;
}
.tab{
    font-size: 17px;
    font-weight: 400;
    font-family: var(--font-family);
    display: inline-block;
    text-align: center;
    padding: 5px 15px 0;
    height: 50px;
    line-height: 42px;
    border-bottom: 5px solid transparent;
    user-select: none;
    -moz-user-select: none;
    cursor: pointer;
    border-radius: 0;
}
.tab:hover{
    background-color: rgba(0,0,0,.05);
}
.currentTab{
    border-color: rgb(var(--theme_color));
}
.tabsBody{
    padding: 20px 10px 0;
}