.title{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 24px;
    text-align: left;
    margin: 0;
    margin-bottom: 15px;
}
.subtitle{
    font-family: var(--font-family);
    font-weight: 600;
    font-size: 18px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
}
.content{
    font-family: var(--font-family);
    font-weight: 300;
    font-size: 16px;
    text-align: left;
    margin: 0;
    margin-bottom: 10px;
}